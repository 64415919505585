<template lang="pug">
div.close-circle(@click="click")
  span.close-circle-el.close-circle-el-1
  span.close-circle-el.close-circle-el-2
</template>

<script>
export default {
  name: "CloseCircle",
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>
